<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>


          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Name") }}</label>
            <b-form-input
                v-model="filter.like.name"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Address") }}</label>
            <b-form-input
                v-model="filter.like.address"
                class="d-inline-block mr-1"
            />
          </b-col>

          <!-- Type-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Zip") }}</label>
            <b-form-input
                v-model="filter.like.zip"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Location") }}</label>
            <b-form-input
                v-model="filter.like.city"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Language')" label-for="realEstate-branch">
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  v-model="filter.in.lang"
                  :options="[
        { id: 'EN', name: 'English' },
        { id: 'ES', name: 'Spanish' },
        { id: 'FR', name: 'French' },
        { id: 'DE', name: 'German' },
        { id: 'IT', name: 'Italian' },
        { id: 'PT', name: 'Portuguese' },
        { id: 'RU', name: 'Russian' },
        { id: 'CN', name: 'Chinese' },
        { id: 'JP', name: 'Japanese' }      ]"
                  class="w-100"
                  label="name"
                  :reduce="(branch) => branch.id"
                  multiple
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Templates') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->

            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="buid"
                    placeholder="Branch"
                    :options="allBranches"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"
                    :clearable="false"

                />
                <span class="mr-1"></span>
                <b-button :disabled="buid==null" variant="primary" @click="onTemplateSelect(0)">
                  <span class="text-nowrap">{{ msg('New Template') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="bank-table"
            striped
            hover
            responsive
            v-bind:items="templates"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >

          <template #cell(buid)="data">
            <b-badge variant="primary">{{ getBranchName(data.value) }}</b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge variant="primary">{{ msg(data.value) }}</b-badge>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>


        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'ContractTemplates',
  components: {},
  data() {
    return {
      buid: null,

      loading: true,
      amount: 10,
      templates: [],
      fields: [],
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", template: true},
        lte: {},
        gte: {},
        in: {}
      },
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },
      statusVariants:
          {"NEW": "primary", "ACTIVE": "success", "EXPOSE": "warning", "DISABLED": "danger"},

      statusTypeVariants:
          {"BUY_CONTRACT": "primary", "BUY_OFFER": "success", "RENT_CONTRACT": "warning", "RENT_OFFER": "danger"},

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {template: false},
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      featureInfoModal: {
        id: 'feature-modal',
        title: '',
        content: ''
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getBranchName']),
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },

    allTemplates() {
      return this.templates
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('contractTemplate', ['getAllTemplates']),


    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this
      this.fields = [
        {
          key: 'status',
          label: $this.msg('Type'),
          sortable: true
        },

        {
          key: 'name',
          label: $this.msg('Name'),
          sortable: true
        },
        {
          key: 'lang',
          label: $this.msg('Language'),
          sortable: true
        },
        {
          key: 'buid',
          label: $this.msg('Branch'),
          sortable: true
        },


      ]
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    infoFeature(item, index, button) {
      this.featureInfoModal.title = `Row index: ${index}`
      this.featureInfoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.featureInfoModal.id, button)
    },
    copyId(id) {
      console.log("the id copied was: ", id)
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllTemplates({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: $this.sort.by,
        sortby: $this.sort.direction,
      }).then(this.onTemplatesRetrieve, (error) => $this.error = error)

    },
    onTemplatesRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.templates = data["Templates"];
      this.loading = false;
    },
    onTemplateSelect(id) {
      this.$router.push(`/real_estate/contracts/template/new/${this.buid}`);

    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(bank) {
      this.$router.push(`/real_estate/contracts/template/edit/${bank.id}`);
    },
    submitTemplate() {
      this.$root.$emit('crm::submitTemplate')
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.template = false;
    }
  }
}
</script>

<style>

</style>
